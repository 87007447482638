import React from 'react'

import captialBuilding from "../../images/refactor/LobbyView_CapitolBuilding.png"
import captialBuildingPerson from "../../images/refactor/LobbyView_CapitolBuildingPerson.png"
import { StaticImage } from 'gatsby-plugin-image';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import "./landingNew.scss"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FooterNew from '../footer/FooterNew';


export default function Landing() {
  return <div id="landing">
    <div id="header-container">
      <header>
        <img id="capital-building" src={captialBuilding} alt="capital building"/>
        <img id="capital-building-person" src={captialBuildingPerson} alt="capital building person"/>
        <div>
          <h1>Where data science <br/>and interest politics converge.</h1>
          <p>Access over 1,000,000+ lobbying reports.</p>
          <div className="fused-input-button">
            <Form.Control
              type="text"
              id="inputPassword5"
            />
            <Button>Search</Button>
          </div>
        </div>
      </header>
    </div>

    <svg width="0" height="0">
      <clipPath id="header-clip-desktop" clipPathUnits="objectBoundingBox">
        <path d="M 0,0 L 0,1 C 0.5,0.8 0.95,1.2 1,0.85 L 1,0 Z"></path>
      </clipPath>

      <clipPath id="header-clip-md" clipPathUnits="objectBoundingBox">
        <path d="M 0,0 L 0,1 C 0.4,0.95 0.9,1.1 1,0.85 L 1,0 Z"></path>
      </clipPath>

      <clipPath id="header-clip-sm" clipPathUnits="objectBoundingBox">
        <path d="M 0,0 L 0,1 C 0.4,0.95 0.9,1.05 1,0.95 L 1,0 Z"></path>
      </clipPath>
    </svg>

    {/* -------------------- SUPPORTED BY -------------------- */}
    <Container className="py-5">
      <div className="label py-4">Supported by</div>
      <div className="landing-supported-by">
        <div className="landing-supported-by-sponsor">
          <span className="landing-supported-by-logo">
            <StaticImage src="../../images/refactor/mit.png" alt="Massachusetts Institute of Technology"/>
          </span>
          <p className="landing-supported-by-name">Massachusetts <br/> Institute of <br/> Technology</p>
        </div>

        <div className="landing-supported-by-sponsor">
          <span className="landing-supported-by-logo">
            <StaticImage src="./nsf.png" alt="National Science Foundation Logo"/>
          </span>
          <p className="landing-supported-by-name">National <br/> Science <br/> Foundation</p>
        </div>

        <div className="landing-supported-by-sponsor">
          <span className="landing-supported-by-logo">
            <StaticImage src="./rsf.jpg" alt="Russel Sage Logo"/>
          </span>
          <p className="landing-supported-by-name">Russel <br/> Sage <br/> Foundation</p>
        </div>
      </div>
    </Container>
    <Container className="py-5">
      <hr/>
    </Container>

    {/* -------------------- GETTING STARTED CARDS -------------------- */}
    <Container className="landing-getting-started py-5">
      <h2 className="text-center">Let's Get Started</h2>
      <div className="text-center pb-5">With over 1,000,000+ lobbying reports to explore, it can feel a little daunting to know where to start. Here’s a few ways we can help with your search.</div>
      <Row className="pb-5">
        <Col md={4} sm={12}>
          <Card>
            <div className="landing-getting-started-image-container">
              <StaticImage 
                src="./Landing_Dataset.png"
                height={200}
                alt="dataset"
              />
            </div>
            <div className="landing-getting-started-card-details">
              <h3>Access Curated Datasets</h3>
              <div>With our extensive research and methods. We’ve gathered already polished datasets downloadable and ready to share--just for you.</div>
              <a className="link"><span>Browse Our Datasets</span></a>
            </div>
          </Card>
        </Col>
        <Col md={4} sm={12}>
          <Card>
            <div className="landing-getting-started-image-container">
              <StaticImage
                src="./Landing_DataViz.png"
                height={200}
                alt="visualization"
              />
            </div>
            <div className="landing-getting-started-card-details">
              <h3>Explore Visualizations</h3>
              <div>from our data of lobbying reports. Curious about the correlations within the lobbying industry? Take a look at our visualizations.</div>
              <a className="link"><span>Go Explore</span></a>
            </div>
          </Card>
        </Col>
        <Col md={4} sm={12}>
          <Card>
            <div className="landing-getting-started-image-container">
              <StaticImage
                src="./Landing_API.png"
                height={200}
                alt="api"
              />
            </div>
            <div className="landing-getting-started-card-details">
              <h3>Integrate An API</h3>
              <div>with just the click of a button. Yes, it’s that easy. You can dig deep and narrow down specific searches with our API.  </div>
              <a className="link"><span>Try Our API</span></a>
            </div>
          </Card>
        </Col>
      </Row>
      <br/><br/>
      <hr/>
    </Container>

    {/* -------------------- LV VIDEO -------------------- */}
    <div id="video-container">
      <Container className="py-5">
        <Row>
          <div id="video-background"><StaticImage src="../../images/refactor/API_4.png" alt="Massachusetts Institute of Technology"/></div>
          <Col md={4} sm={12}>
            <div id="video-text">
              <h2>About LobbyView</h2>
              <p>Winner of the 2020 International Political Economic Society (IPES)</p>
            </div>
          </Col>
          <Col md={8} sm={12}>
            <div className="video-aspect-ratio-container">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/29OMHHuUzUw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen/>
            </div>
          </Col>
        </Row>
     
        <br/><br/><br/><br/>
        <hr/>
      </Container>
    </div>

    {/* -------------------- WHAT RESERACHERS SAY ABOUT LOBBYVIEW -------------------- */}
    <Container className="landing-testimonials py-5">
      <h2 className="text-center pb-5">See what researchers have to say about LobbyView</h2>
      <Row>
        <Col md={4}>
          <div className="testimonial-organization">Organization Name</div>
          <div>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada mauris bibendum ipsum facilisis.”</div>
          <div className="testimonial-person pt-3">Name of person</div>
          <div className="testimonial-role">Role, Company name</div>
        </Col>
        <Col md={4}>
          <div className="testimonial-organization">Organization Name</div>
          <div>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada mauris bibendum ipsum facilisis.”</div>
          <div className="testimonial-person pt-3">Name of person</div>
          <div className="testimonial-role">Role, Company name</div>
        </Col>
        <Col md={4}>
          <div className="testimonial-organization">Organization Name</div>
          <div>“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Malesuada mauris bibendum ipsum facilisis.”</div>
          <div className="testimonial-person pt-3">Name of person</div>
          <div className="testimonial-role">Role, Company name</div>
        </Col>
      </Row>
      <br/><br/>
    </Container>

    {/* -------------------- FOOTER -------------------- */}
    <FooterNew/>
  </div>
}